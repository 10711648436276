body {
  text-shadow: #fff 0px 1px 1px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
  letter-spacing: 0.03333em;
}

html * {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

p {
  line-height: 1.5;
}

#root {
  background: #ffffff;
  position: relative;
  z-index: 1;
}

@keyframes slide-from-right {
  0% {
      opacity: 0;
      transform: translateX(35%) skew(-4deg, 0) scale(0.92);
      filter: blur(8px);
  }
  60% {
    opacity: 0.42;
    transform: translateX(20%) skew(-3deg, 0) scale(0.99);
    filter: blur(5px);
  }
  80% {
      opacity: 0.8;
      transform: translateX(5%) skew(-2deg, 0) scale(0.99);
      filter: blur(1.5px);
  }
  100% {
    opacity: 1;
    transform: translateX(0%) skew(0deg, 0) scale(1);
    filter: blur(0px);
  }
}

.slideIn {
  opacity: 0;
  transform: translateX(35%) skew(-4deg, 0) scale(0.92);
  filter: blur(8px);
  animation: slide-from-right 0.25s linear 0s 1 normal forwards;
}
